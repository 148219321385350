export const messages = {
  saveButtonLabel: {
    id: 'authoring.texteditor.sourcecodemodal.next.label',
    defaultMessage: 'Save',
    description: 'Label for Save button for the source code editor',
  },
  titleLabel: {
    id: 'authoring.texteditor.sourcecodemodal.title.label',
    defaultMessage: 'Edit Source Code',
    description: 'Title for the source code editor',
  },
};
export default messages;
